<template>
  <v-row class=" row--35" align="center">
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <h2 class="heading-title">What we do?</h2>
          <p class="description">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which dont look even slightly
            believable.
          </p>
        </div>
        <!-- End .section-title -->

        <v-row class="row mt--30">
          <v-col cols="12">
            <div class="about-us-list">
              <h3 class="heading-title">Our Working Process.</h3>
              <div class="progress-wrapper">
                <div
                  class="rn-progress-charts progress-bar--3 custom-color--2 mt--40"
                >
                  <h6 class="heading heading-h6">Designing</h6>
                  <div class="progress">
                    <div class="progress-bar" style="width: 95%;">
                      <span class="percent-label">95%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="rn-progress-charts progress-bar--3 custom-color--3 mt--40"
                >
                  <h6 class="heading heading-h6">Managment</h6>
                  <div class="progress">
                    <div class="progress-bar" style="width: 65%;">
                      <span class="percent-label">65%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="rn-progress-charts progress-bar--3 custom-color--1 mt--40"
                >
                  <h6 class="heading heading-h6">Marketing</h6>
                  <div class="progress">
                    <div class="progress-bar" style="width: 75%;">
                      <span class="percent-label">75%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="rn-progress-charts progress-bar--3 custom-color--4 mt--40"
                >
                  <h6 class="heading heading-h6">Development</h6>
                  <div class="progress">
                    <div class="progress-bar" style="width: 85%;">
                      <span class="percent-label">85%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col lg="5" md="5" cols="12" class="col-lg-5 mt_md--40 mt_sm--40">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/about/about-3.jpg"
          alt="About Images"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
